import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegEnvelope, FaPhone } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import style from './../assets/scss/pages/contact.module.scss';

// Validation Schema
const schema = yup.object().shape({
  name: yup.string().required('Name is required').min(3, 'Name must be at least 3 characters'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  subject: yup.string().required('Subject is required').min(5, 'Subject must be at least 5 characters'),
  message: yup.string().required('Message is required').min(10, 'Message must be at least 10 characters'),
});

export default function Contact() {
  const form = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const sendEmail = (data) => {
    emailjs
      .sendForm('service_cx96c59', 'template_10h6z3t', form.current, 'bXByug8NDbPHaP7Kt')
      .then(
        (response) => {
          console.log("EmailJS Response:", response);
          toast.success('Email sent successfully!', { position: 'top-right' });
          reset(); // Clear the form after successful submission
        },
        (error) => {
          console.error("EmailJS Error:", error);
          toast.error(`Failed to send email. Please try again later.`, { position: 'top-right' });
        }
      );
  };

  return (
    <div>
      <ToastContainer /> {/* Toast Container for notifications */}
      <section className={style.heading}>
        <div className="container">
          <h1>Need help? Say Hello</h1>
          <p>
            Feel free to reach out to us using the options below, and our
            dedicated team will respond to your inquiries promptly.
          </p>
        </div>
      </section>

      <div className="container">
        <div className={style.aboutUs1__info}>
          <div className={style.aboutUs1__info__content}>
            <div className={style.aboutUs1__info__content__serviceItem}>
              <h4>
                <FaRegEnvelope />
              </h4>
              <div>
                <h3>Email Us</h3>
                <p>Send us an email and we'll get back to you shortly.</p>
              </div>
            </div>
            <div className={style.aboutUs1__info__content__serviceItem}>
              <h4>
                <FaPhone />
              </h4>
              <div>
                <h3>Call Us</h3>
                <p>Reach out to our support team via phone.</p>
              </div>
            </div>
          </div>

          <div>
            <h2>How can we help you today?</h2>
            <form
              ref={form}
              className={style.contactForm}
              onSubmit={handleSubmit(sendEmail)}
            >
              <div className={style.parentformField}>
                <div className={style.formField}>
                  <label>Name:</label>
                  <input type="text" {...register('name')} />
                  {errors.name && <p className={style.error}>{errors.name.message}</p>}
                </div>
                <div className={style.formField}>
                  <label>Email:</label>
                  <input type="email" {...register('email')} />
                  {errors.email && <p className={style.error}>{errors.email.message}</p>}
                </div>
                <div className={style.formField}>
                  <label>Subject:</label>
                  <input type="text" {...register('subject')} />
                  {errors.subject && <p className={style.error}>{errors.subject.message}</p>}
                </div>
              </div>
              <div className={style.formField}>
                <label>Message:</label>
                <textarea
                  {...register('message')}
                  rows={3}
                  style={{ display: 'flex', width: '100%', paddingBottom: 20 }}
                ></textarea>
                {errors.message && <p className={style.error}>{errors.message.message}</p>}
              </div>
              <button style={{ marginTop: 20 }} type="submit">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d464.9973754605175!2d80.87258763479491!3d26.666862112588408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bf7c48f014e5b%3A0x84fd2423981b3cf0!2sMahakaleshwar%20Mahadev!5e0!3m2!1sen!2sin!4v1733942814868!5m2!1sen!2sin"
        style={{ border: '0', width: '100%', height: '300px' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <ToastContainer/>
    </div>
  );
}
