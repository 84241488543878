import React from 'react';
import {Link} from "react-router-dom"
import { useParams } from 'react-router-dom';
import { services } from '../fetures/config'; // Ensure this is the correct path to your services data
import styles from '../assets/scss/pages/subservice.module.scss'; // Custom SCSS file
import HeroContact from '../componnet/heroContact';

export default function Subservice() {
  const { label } = useParams();

  const createSlug = (str) => str.toLowerCase().replace(/\s+/g, '-');

  const service = services.find(svc => createSlug(svc.label) === label);

  if (!service) return <div>Service not found!</div>;

  return (
    <div className={styles.subservice}>

      <section className={styles.heading}>
        <div className='container'>
          <h1>{service.label}</h1>
          <p ><Link style={{color:"#004676", fontWeight:"600"}} to="/">Home</Link> > {service.label}</p>
          {/* <p>{service.description2}</p> */}
        </div>
      </section>

      <section className={styles.info}>
        <div className="container">
          <div className={styles.whySection}>
            <div className={styles.imageColumn}>
              {/* You can replace the src with the actual image URL */}
              <img src={service.img} alt="AI and Machine Learning Illustration" />
            </div>
            <div className={styles.textColumn} >
              <h2>Why {service.label}?</h2>
              {/* <p>{service.why}</p> */}
              <ul >
                {service.benefits?.map((benefit, index) => ( 
                  <div style={{display:"flex", alignItems:"flex-start", gap:"0.5rem"}}>
                  <img src="/assets/icon_check.png" alt="" />
                  <li key={index}>{benefit}</li>
                  </div>

                ))}
              </ul>
            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className={styles.useCases}>
            <div className={styles.head} data-aos="fade-up">
                <h2>Use Cases of {service.label}</h2>
            <p>{service.usecaseHeadDescription}</p>
            </div>
            <div className={styles.useCaseList}>
              {service.useCases?.map((useCase, index) => ( // Using optional chaining here as well
                <div key={index} className={styles.useCaseItem} >
                  <div className={styles.imge}>

                  <img src={useCase.image} alt="" />
                  </div>
                  <div className={styles.content}>
                  <h3>{useCase.title}</h3>
                  <p>{useCase.description}</p>

                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <HeroContact />
    </div>
  );
}
