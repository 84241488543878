import React from 'react';
import styles from '../assets/scss/componnet/heroContact.module.scss'; // SCSS file for styling
import { useNavigate } from 'react-router-dom';

export default function HeroContact() {
  const navigate = useNavigate();
  const contact = () => {
    navigate("/contact-us")
   }
  return (
    <section className={styles.heroContact}>
      <div className='container'>
        <div className={styles.heroContact__wrp} style={{padding:"2rem 1rem"}}>
          {/* Image Section */}
          <div className={styles.heroContact__wrp__imageSection}>
            <img
              src="/assets/hero.webp" // Update with actual image path
              alt="Hero Contact"
            />
          </div>

          {/* Text Section */}
          <div className={styles.heroContact__wrp__textSection}>
            <h6>All solution</h6>
            <h2>Let’s Build the Future Together</h2>
            <p>
              Ready to embark on your digital transformation journey? Whether you
              need to modernize your infrastructure, enhance data capabilities,
              or create world-class applications, one08.tech has the expertise to
              drive your success.
            </p>
            <button className={styles.contactButton} onClick={contact}>Contact Us</button>
          </div>
        </div>
      </div>
    </section>
  );
}
