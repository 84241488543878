import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import Home from './pages/home';
import Contact from './pages/contact';
import About from './pages/about';
import Nav from './componnet/nav';
import Footer from './componnet/footer';
import Service from './pages/service';
import Subservice from './pages/subservice';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function for the animations
      once: true, // Whether animation should happen only once
    });
  }, []);


  const [isScrollTopVisible, setScrollTopVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTopVisible(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <BrowserRouter>
     <ScrollToTop />
    <Nav/>
    <Routes>
        <Route index element={<Home />} />
        <Route path="about-us" element={<About />} />
        <Route path="our-services" element={<Service />} />
        <Route path="our-services/:label" element={<Subservice />} />
        <Route path="contact-us" element={<Contact />} />
        <Route path="*" element={<Navigate to="/" />} />
        
    </Routes>
    <Footer/>
    {isScrollTopVisible && (
          <button
            onClick={scrollToTop}
            className="scroll-top-button"
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: 1000,
              background: "#02ad90",
              color: "#fff",
              border: "none",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              cursor: "pointer",
            }}
          >
            ↑
          </button>
        )}
  </BrowserRouter>
  );
}

export default App;



function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  return null;
}