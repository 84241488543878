import React, { useEffect, useState } from 'react';
import style from "../assets/scss/pages/home.module.scss";
import HeroContact from '../componnet/heroContact';
import { services } from '../fetures/config';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Array of image sources and their corresponding animations
const images = [
  { src: 'path/to/image1.png', animation: 'fadeIn' },
  { src: 'path/to/image2.png', animation: 'slideIn' },
  { src: 'path/to/image3.png', animation: 'zoomIn' },
  { src: 'path/to/image4.png', animation: 'fadeIn' },
  { src: 'path/to/image5.png', animation: 'slideIn' },
];

export default function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  // Function to change the image every few seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const serviceNavigate = (e, service) => {
    e.preventDefault();
    const url = `/our-services/${service.label.toLowerCase().replace(/\s+/g, '-')}`;
    navigate(url);
  };



  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    arrows:false,
    speed: 1500,

  };
  const contact = () => {
   navigate("/contact-us")
  }
  return (
    <>
      <section className={style.banner}>
        <div className="container">
          <div className={style.banner__content}>
            <div className={style.banner__wrp__content}>
              <h1 data-aos="fade-up">Transforming Data into Intelligent Solutions</h1>
              <p data-aos="fade-up" data-aos-duration="1500">We’re your trusted partner in building intelligent, scalable, and future-ready solutions that transform the way businesses operate in the digital age. Whether you're aiming to streamline operations, harness the power of data, or create cutting-edge digital experiences, we have the expertise and technology to bring your vision to life.</p>
              <button className={style.banner__wrp__content__ctaButton} onClick={contact}>Contact Us</button>
            </div>

            <div className={style.banner__largeImage}>
              <img data-aos="zoom-in" src="/assets/home.webp" alt="" />
            </div>
          </div>
        </div>
      </section>



      {/* Add new sections here */}
      <section className={style.services}>
        {/* <img src="/assets/gbg_2.png" alt="" /> */}
        <div className="container">
          <div className={style.head}>
            {/* <h6>All Services</h6> */}
            <h2>Explore our services</h2>
            <p>We offer a diverse range of services designed to empower your organization with the tools and technologies needed to thrive in today’s data-driven world.</p>
          </div>
          <div>
          <div className={style.services__grid}>
            {services.map((service) => {
              return (
                <div >

                <div className={style.services__grid__serviceItem} >
                <div class={style.image_wrapper}>
                  <img src={service.img} alt="" />
                 </div>
                  <div className={style.services__grid__serviceItem__content}>
                    <h3>{service.label}</h3>
                    <p>{service.description}</p>
                    <button  onClick={(e) => serviceNavigate(e, service)}>More info <svg xmlns="http://www.w3.org/2000/svg" width={15} viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></button> 
                  </div>
                </div>
                </div>
              )
            })}
          </div>
          </div>
        </div>
      </section>

      <section className={style.aboutUs}>
        <div className="container">
          <div className={style.aboutUs__info}>
            <div className={style.aboutUs__info__content}>
              <h2>Who we are?</h2>
              <p>
                One08.tech is a team of innovative tech experts specializing in Cloud Engineering, Data Engineering, AI & Machine Learning, Analytics, and App Development. We empower businesses with customized, cutting-edge digital solutions, driving growth and efficiency through technology and data-driven strategies. Your success is our mission
              </p>
              <button className={style.aboutUs__info__content__ctaButton} onClick={contact}>Contact us</button>
            </div>
            <img data-aos="fade-left" src="assets/about.webp" alt="Our Team" />
          </div>
        </div>
      </section>

      <section className={style.solutions}>
        <div className="container">
          <div className={style.head} data-aos="fade-up">
            <h2>Explore our solutions</h2>
            <p>We offer a comprehensive range of innovative technology solutions designed to accelerate your business transformation and empower you to thrive in a digital-first world.</p>
          </div>
          <div className={style.solutions__grid}>

            <div className={style.solutions__grid__solutionItem} data-aos="fade-up">
              <img src="assets/solution (1).webp" alt="EMM Solution" />
              <div className={style.solutions__grid__solutionItem__info_solution} >
                <h3>Enterprise Mobile Management (EMM) Solution</h3>
                <p>
                  Stay in control of your business's mobile ecosystem with one08.tech’s Enterprise Mobile Management (EMM) solution. Our comprehensive EMM platform empowers organizations to manage, secure, and optimize mobile devices, apps, and data across their enterprise, ensuring seamless and secure mobility for your workforce.
                </p>

              </div>
            </div>
            <div className={style.solutions__grid__solutionItem} data-aos="fade-up">
              <img src="assets/solution (2).webp" alt="EMM Solution" />
              <div className={style.solutions__grid__solutionItem__info_solution}>
                <h3>Deploy AI/ML Models in 90%
                  less Time and Cost!</h3>
                <p>
                  Accelerate your journey into artificial intelligence with one08.tech's cutting-edge solution that enables businesses to deploy AI and machine learning models in 90% less time and cost. Our streamlined approach simplifies the complexities of model deployment, allowing you to focus on what truly matters—driving innovation and value.
                </p>

              </div>
            </div>
          </div>
        </div>
      </section>

      <HeroContact />
    </>
  );
}
