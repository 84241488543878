import React from 'react'

import styles from '../assets/scss/componnet/banner.module.scss'; 
import { Link } from 'react-router-dom';
export default function Banner() {
  return (
    <section className={styles.heading}>
        <div className='container'>
          <h1>About us</h1>
          {/* <p ><Link style={{color:"#004676", fontWeight:"600"}} to="/">Home</Link> > About</p> */}

          {/* <p>At one08, we are dedicated to empowering businesses through innovative technology solutions that drive digital transformation and operational efficiency. Founded with a vision to harness the full potential of AI, cloud computing, and data-driven technologies, we are committed to delivering solutions that shape the future of business.</p> */}
        </div>
        
      </section>
  )
}
